import { getYear } from 'date-fns'

export const CANONICAL_HOSTNAME = 'omni.co'
export const LEGACY_HOSTNAME = 'exploreomni.com'

export const GA_TRACKING_ID = 'GTM-MNRVVBM'
export const SITE_TITLE = 'Omni Analytics'
export const COPYRIGHT = `© ${getYear(new Date())} Omni Analytics`

export const RUDDERSTACK_WRITE_KEY = '2XM30f94XBew2vOiaSwt3Wvo1BD'
export const RUDDERSTACK_DATAPLANE_URL =
  'https://exploreomnxjvb.dataplane.rudderstack.com'

export const RUDDERSTACK_DEV_WRITE_KEY = '2cNDCmk9BWXKXjdUcQVdkwoRjHb'

export const BLOG_PAGE_SIZE = 12

export const trialText = {
  cta: 'Free Trial',
  to: '/demo',
  // page stuff
  conversionId: 'free-trial',
  description: 'Get reports and dashboards you can use right now.',
  formTitle: 'Request a free trial',
  title: 'Try Omni with your data',
  formButton: 'Request Free Trial',
}
